import Cookie from 'js-cookie';
import {inspectletDataAttributes} from '../form/inspectletDataAttributes';
import {lookupCenter} from '../form/lookupCenter';
import {displayAvailableAppointments} from '../form/displayAvailableAppointments';
import {confirmationPageInit, confirmationPageCheckboxClick, confirmationPageTextClick} from '../form/osConfirmationPage';
import {initiateSFInfoPartial, saveLeadInfo, retrieveAppointments, saveLeadInfoPartial, saveUpdateLeadInfoAndRetrieveInfo} from '../form/initiateSFInfo';
import {defaultMonthCalendar, twoMonthCalendar, oneMonthCalendarWeekControls} from "../form/calendarVersion";
import {getTimeZoneOffsets, getActualTime, printActualTime} from '../form/time';
import {submitForm} from '../form/submitForm';
import * as ccForms from '../form/formFunctions';
import {w1_step3, initiateStep3MonthCal} from '../form/w1_step3';
import {w1_step3_2month,initiateStep3TwoMonthCal} from '../form/w1_step3_2month';
import moment from 'moment';
import {setOathToken} from '../form/salesforceOath';
import {is_w1, is_w2} from '../global/functions';
import {showWaitingIcon,hideWaitingIcon} from '../form/w1_helper_functions';
import jstz from 'jstz';
import {initMenu,getSuggestions} from '../form/addressVerification';
import {saveCampaign} from '../form/formFunctions';
import {addHighlightToCalDays, addControlDaysForComparison, progressBar,spanishLanguageTest,removeThirdQuestion} from './abtesting';
import { updateNumber } from '../global/setUserParams';
import {helpers} from '../global/setUserParamsLegacy';
import {showCenterSelectionStep,initializePageGatherInfo,saveLeadCall,autofillPageGatherInfo,fewQuestionsStepAddLabels,formAutocompleteSetUp,fewQuestionsStepAnimateToTop} from '../form/personalInfoFirstFlow';
export default {
  init() {
    let is__w1 = is_w1();
    let is__w2 = is_w2();
    function getParam(p){
      var match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
      return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    }
    global.formHasRun = false;
    let callCenterNumber = $('.trans_id').first().text() || '888-651-9950';
    let zip;

    const validStates = ['AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY', 'AE', 'AA', 'AP', 'al', 'ak', 'as', 'az', 'ar', 'ca', 'co', 'ct', 'de', 'dc', 'fm', 'fl', 'ga', 'gu', 'hi', 'id', 'il', 'in', 'ia', 'ks', 'ky', 'la', 'me', 'mh', 'md', 'ma', 'mi', 'mn', 'ms', 'mo', 'mt', 'ne', 'nv', 'nh', 'nj', 'nm', 'ny', 'nc', 'nd', 'mp', 'oh', 'ok', 'or', 'pw', 'pa', 'pr', 'ri', 'sc', 'sd', 'tn', 'tx', 'ut', 'vt', 'vi', 'va', 'wa', 'wv', 'wi', 'wy', 'ae', 'aa', 'ap', 'Al', 'Ak', 'As', 'Az', 'Ar', 'Ca', 'Co', 'Ct', 'De', 'Dc', 'Fm', 'Fl', 'Ga', 'Gu', 'Hi', 'Id', 'Il', 'In', 'Ia', 'Ks', 'Ky', 'La', 'Me', 'Mh', 'Md', 'Ma', 'Mi', 'Mn', 'Ms', 'Mo', 'Mt', 'Ne', 'Nv', 'Nh', 'Nj', 'Nm', 'Ny', 'Nc', 'Nd', 'Mp', 'Oh', 'Ok', 'Or', 'Pw', 'Pa', 'Pr', 'Ri', 'Sc', 'Sd', 'Tn', 'Tx', 'Ut', 'Vt', 'Vi', 'Va', 'Wa', 'Wv', 'Wi', 'Wy', 'Ae', 'Aa', 'Ap'];
    global.locateCenterForm = `
    <div class="form-group bg-light">
      <div class="click-to-call-container"></div>
      <div class="form-locate-center">
        <div class="row">
          <div class="col-sm-8">
            <h2 id="select-a-center-header" class="h5 font-primary" style="border: none !important; margin-bottom:0;">Select A Center to Schedule Online</h2>
          </div>
          <div class="col-sm-4">
            <p class="text-muted text-sm-right required-fields-message">*Indicates required field</p>
          </div>
        </div>

        <div class="form-locate-center-lookup">
          <p>Enter your ZIP Code to find your ClearChoice Center.</p>
          <div class="row align-items-center">
            <div class="col-sm-8">
              <div class="form-group has-float-label mb-sm-0">
                <input id="form-locate-center-address" type="tel" class="form-control form-control-lg" placeholder="*ZIP Code" required>
                <label for="form-locate-center-address">*ZIP Code</label>
              </div>
            </div>
            <div class="col-sm-4 pl-sm-0">
              <p class="mb-0 text-right"><span class="btn btn-outline-primary px-3 py-2">Find Center</span></p>
            </div>
          </div>
        </div>
      </div>

      <div class="form-locate-center-results d-none">
        <div class="row">
          <div class="col-sm-7">
            <div class="list-group mb-2 mb-sm-0"></div>
          </div>
          <div class="col-sm-5">
            <div id="form-map"></div>
          </div>
        </div>
      </div>
    </div>
  `;

    /**
     * Add/removes [not-]placeholder-shown class for inputs.
     * For getting around IE's lack of support for :placeholder-shown
     */
    let placeholderShown = function() {
      if ( $(this).val() != '' ) {
        $(this).removeClass('placeholder-shown');
        $(this).addClass('not-placeholder-shown');
      } else {
        $(this).addClass('placeholder-shown');
        $(this).removeClass('not-placeholder-shown');
      }
    };

    // variables for iSpot pixels
    var userId;
    var segment20;
    var channel;
    var campaignid;
    /**
     * For getting parsing and tracking campaigns of interest in iSpot pixel
    */
    let getCampaignSource = function(){
        campaignid = global.ccUserInfo.trans_id;
        if(campaignid  != '' && campaignid != undefined && campaignid != null){
          if((campaignid).toString().toLowerCase().includes('sem')){
            channel = "SEM";
          }else if((campaignid).toString().toLowerCase().includes('pmax') || (campaignid).toString().toLowerCase().includes('display')){
            channel = "Display";
          }else if((campaignid).toString().toLowerCase().includes('facebook')){
            channel = "Paid_Social";
          }else if((campaignid).toString().toLowerCase().includes('youtube') || (campaignid).toString().toLowerCase().includes('video')){
            channel = "Video";
          }
        }else{
          channel = "Organic_Direct";
        }
        return channel;
    }

    /**
     * For getting parsing and tracking leadSegment20 groups of interest in iSpot pixel
    */
    let getLeadSegment20 = function(){
      if(global.ccUserInfo.leadSegment20 <= 20 || global.ccUserInfo.leadSegment20 >=16){
        segment20 = "G1";
      }else if(global.ccUserInfo.leadSegment20 <= 15 || global.ccUserInfo.leadSegment20 >= 11){
        segment20 = "G2";
      }else if(global.ccUserInfo.leadSegment20 <= 10 || global.ccUserInfo.leadSegment20 >= 6){
        segment20 = "G3";
      }else if(global.ccUserInfo.leadSegment20 <= 5 || global.ccUserInfo.leadSegment20 >= 1){
        segment20 = "G4";
      }else{
        //relax
      }
      return segment20;
    }
    /**
     * iSpot pixel template
    */
    function addIspotPixel(type,channel,userId){
      // does leadSegment exist or not
      if(getLeadSegment20() != undefined && getLeadSegment20() != ""){
          if(userId != undefined && channel != undefined){
            $('head').append(`<img src='https://pt.ispot.tv/v2/TC-5423-1.gif?app=web&type=${type}&customdata=${channel},${segment20}&refid=${userId}' style='display:none' alt='' />`);
          }else if(channel != undefined){
            $('head').append(`<img src='https://pt.ispot.tv/v2/TC-5423-1.gif?app=web&type=${type}&customdata=${channel},${segment20}' style='display:none' alt='' />`);
          }else if(userId != undefined){
            $('head').append(`<img src='https://pt.ispot.tv/v2/TC-5423-1.gif?app=web&type=${type}&refid=${userId}&customdata=${segment20}' style='display:none' alt='' />`);
          }else{
            $('head').append(`<img src='https://pt.ispot.tv/v2/TC-5423-1.gif?app=web&type=${type}&' style='display:none' alt='' />`);
          }
      }else{
          if(userId != undefined && channel != undefined){
            $('head').append(`<img src='https://pt.ispot.tv/v2/TC-5423-1.gif?app=web&type=${type}&customdata=${channel}&refid=${userId}' style='display:none' alt='' />`);
          }else if(channel != undefined){
            $('head').append(`<img src='https://pt.ispot.tv/v2/TC-5423-1.gif?app=web&type=${type}&customdata=${channel}' style='display:none' alt='' />`);
          }else if(userId != undefined){
            $('head').append(`<img src='https://pt.ispot.tv/v2/TC-5423-1.gif?app=web&type=${type}&refid=${userId}' style='display:none' alt='' />`);
          }else{
            $('head').append(`<img src='https://pt.ispot.tv/v2/TC-5423-1.gif?app=web&type=${type}&' style='display:none' alt='' />`);
          }
        }
    }

    //switched form flow setup for gather info step
    if(global.switchedSteps){
      initializePageGatherInfo();
    }

    $(window).on('load', function () {
      // test skipping step 1 for partial form leads when data is populated. Only on new schedule form.
      // if(global.switchedSteps && Cookie.get('cc-pre-form') && window.location.pathname.includes('schedule-consultation') && $("#gform_34").length > 0){
      //   // wait for page 1 to fill
      //   $("#gform_next_button_34_11").click();
      //   hideWaitingIcon();
      //   var targetNode = document.getElementById('gform_next_button_34_11');
      //   var observer = new MutationObserver(function(){
      //     if(!targetNode.className.includes('disabledButton')){
      //       $("#gform_next_button_34_11").click();
      //       hideWaitingIcon();
      //     }
      //   });
      //   observer.observe(targetNode, { attributes: true, childList: true });
      // }
    });


    removeThirdQuestion();

    // first step form changes for colocation
    $( document ).ready(function(){

      if(global.switchedSteps){
        formAutocompleteSetUp();
        global.formHasRun = true;
        // if(window.location.pathname.includes('schedule-consultation') && global.switchedSteps && Cookie.get('cc-pre-form') && $("#gform_34").length > 0){
        //   $('#gform_fields_34 li:gt(1)').hide();
        //   showWaitingIcon('Please Wait');
        // }
      }

      // RESKINNING FOR SCHEDULE NOW
      if(window.location.pathname.includes('schedule-now')){
        $('#nav-sticky-cta').hide();
        $('#nav-sticky-items').hide();
        $('.menu-toggle-button').hide();
        $("#nav-sticky-logo > a").removeAttr("href");
        $(".site-logo").removeAttr("href");
        $(".gf_step_active").addClass("gf_step_active_now");
        $(".gf_step_pending").addClass("gf_step_pending_now");
        $("#input_19_46").addClass("schedNowReskin");

        $("#nav-sticky-phone-number").html("888-486-3443");
        $("#nav-sticky-phone-number").attr('href','tel:8884863443');
        $(".mobile-call").html("888-486-3443");
        $(".mobile-call").attr('href','tel:8884863443');

        $('.accessibilityTextBox .accessibilityBoxText a').eq(0).html('888-486-3443');
        $('.accessibilityTextBox .accessibilityBoxText a').eq(0).attr('href','tel:8884863443');
      }

      if(window.location.pathname.includes('colocation-schedule')){
        $( "#gf_step_19_2 > span.gf_step_label" ).text("PATIENT INFORMATION");
        $('#nav-sticky-cta').hide();
        $('#nav-sticky-items').hide();
        $('.menu-toggle-button').hide();
        $( "#field_19_37").hide();
        $("#nav-sticky-logo > a").removeAttr("href");
        if($('#gf_step_19_1').hasClass("gf_step_active")){
          $('#webform-component-step-find-center--fieldset-header > h4').text("How can we help? Tell us about the patient.")
        }
      }
      if(window.location.pathname.includes('schedule-direct') || window.location.pathname.includes('schedule-consultation') || window.location.pathname.includes('schedule-now')){
        $('#nav-sticky-cta').hide();
        $('#nav-sticky-items').hide();
        $('.menu-toggle-button').hide();
        $( "#field_19_37").hide();
        $("#nav-sticky-logo > a").removeAttr("href");
        let searchParams = window.location.search;
        let redirectUrl = "http://www.clearchoice.com/" + searchParams;
        $("#nav-sticky-logo > a").attr("href", redirectUrl);
        $(".site-logo").attr("href", redirectUrl);
      }
      if(window.location.search.includes('a0S3r00002GtsslEAB')){
        $('#schedule-your-free-consultation-form-container .container h1')[0].innerHTML = "<span>ALBUQUERQUE COLOCATION SCHEDULE</span>";
      }
      if(window.location.search.includes('a0SDo0000000tonMAA')){
        $('#schedule-your-free-consultation-form-container .container h1')[0].innerHTML = "<span>LOUISVILLE COLOCATION SCHEDULE</span>";
      }
      if(window.location.search.includes('a0SDo0000000toxMAA')){
        $('#schedule-your-free-consultation-form-container .container h1')[0].innerHTML = "<span>FORT MYERS COLOCATION SCHEDULE</span>";
      }
      if(window.location.search.includes('a0SDo000000AJMZMA4')){
        $('#schedule-your-free-consultation-form-container .container h1')[0].innerHTML = "<span>CHARLOTTE-UNIVERSITY CITY COLOCATION SCHEDULE</span>";
      }
    });

    // fix size of smarty-streets window on partial form mobile
    $(window).bind("load resize",function(e){
      var $setter = $("#mobile-form .field-address input");
      var $setterDesktop = $(".layout__form-vertical--2col-form input#input_20_4");
      $setterDesktop.siblings(".us-autocomplete-pro-menu:not(#mobile-form)").css("max-width",$setterDesktop.width() + 8 + "px");
      $setterDesktop.siblings(".us-autocomplete-pro-menu:not(#mobile-form)").css("width",$setterDesktop.width() + 8 + "px");
      $setter.siblings("#mobile-form .us-autocomplete-pro-menu").css("max-width",$setter.width() + 8 + "px");
      $setter.siblings("#mobile-form .us-autocomplete-pro-menu").css("width",$setter.width() + 8 + "px");
    });

    // load the default calendar first
    $(document).on('gform_page_loaded', function(){
      /*
      var showTwoMonth = Cookie.get('test-2-month');
      if((!showTwoMonth || typeof showTwoMonth == 'undefined')){
        $( ".make-appointment" ).append( defaultMonthCalendar());
        //fix animation on step 3
        var targetNode = document.querySelector('.make-appointment');
        var observer = new MutationObserver(function(){
            if(targetNode.style.display != 'none'){
              $(".breadcrumb.breadcrumb-arrows").get(0).scrollIntoView();
            }
        });
        observer.observe(targetNode, { attributes: true, childList: true });
      }else{
      }
      */
        //var showHighlightedDays = Cookie.get('testHighlightedScheduleDays');
        // $( ".make-appointment" ).append( twoMonthCalendar());
        // $(".make-appointment").append(oneMonthCalendarWeekControls());
        var newOneMonthSched = Cookie.get("newOneMonthSched");
        if(newOneMonthSched){
          $( ".make-appointment" ).append( defaultMonthCalendar());
          if(window.location.pathname.includes('schedule-now')){
            $('.field-agree-to-terms .ginput_container_checkbox').after('<p class="agree-to-terms-info">After pressing “Schedule My Free Consultation” below, we will hold an hour of the doctor’s time for you. A representative from ClearChoice will attempt to contact you closer to your appointment date to confirm this appointment will still work for you. If you are unable to make your appointment, please call us at <span id="cancellation-phone-number"><a href="tel:8884863443">888-486-3443</a></span> with at least 48 hours notice.</p>');
          }else{
            $('.field-agree-to-terms .ginput_container_checkbox').after('<p class="agree-to-terms-info">After pressing “Schedule My Free Consultation” below, we will hold an hour of the doctor’s time for you. A representative from ClearChoice will attempt to contact you closer to your appointment date to confirm this appointment will still work for you. If you are unable to make your appointment, please call us at <span id="cancellation-phone-number"><a href="888-651-9950">888-651-9950</a></span> with at least 48 hours notice.</p>');
          }
          $(".field-agree-to-terms .gfield_label").remove();
        }else{
          $( ".make-appointment" ).append( twoMonthCalendar());
        }

        //fix animation on step 3
        if(!newOneMonthSched){
          var targetNode2 = document.querySelector('.make-appointment');
          var observer2 = new MutationObserver(function(){
              if(targetNode2.style.display != 'none'){
                $(".breadcrumb.breadcrumb-arrows").get(0).scrollIntoView();
                // add highlight to current month days range, current is set to the first 15 active days
                /*
                if((showHighlightedDays && typeof showHighlightedDays != 'undefined')){
                  addHighlightToCalDays(15);
                } else{
                  // add class to current month days range for control comparison in secondary metric, current is set to the first 15 active days
                  addControlDaysForComparison(15);
                }
                */
              }
          });
          observer2.observe(targetNode2, { attributes: true, childList: true });
        }
    });

      /**
       * function for removing the asterisks in the OS form's step2 fields
       */
    let replaceAsterisks = function( ) {
      let label = $(this).find('.gfield_label');
      let input = $(this).find('input');
      let placeholder = input.attr('placeholder');

      // replace first instance only in the label (ignore the <span required>)
      if( label.length > 0 )
        label.html( label.html().replace('*', '') );

      // replace placeholders (if exists)
      if( input.length > 0 && typeof placeholder !== typeof undefined && placeholder !== false )
        input.attr('placeholder', placeholder.replace('*', '') );
    };

    /**
     * After gform is done rendering
     * - non adi - remove required inputs
     * - w1 - move html elements around to match the old html structure
     * - w2 - Float input labels
     * - Show next/submit buttons before previous on mobile
     */
    $(document).bind('gform_post_render', function(event,formid,currentPage) {

      //remove required field text and asterisks for non adi form
      if($(".adi-schedule").length == 0){
        var fewQuestions = $('.page-few-questions'); // step1
        var gatherInfo = $('.page-gather-info'); // step2

        fewQuestions.find('.gfield').each( replaceAsterisks );
        gatherInfo.find('.gfield').each( replaceAsterisks );
      }

      // Float labels + Gravity Forms (w2 only)
      if( is__w2 ) {
        $('.gform_wrapper .has-float-label').each(function() {
          $('.gform_wrapper .has-float-label .ginput_container').addClass('has-float-label');
          $('.gform_wrapper .gfield.has-float-label').removeClass('has-float-label');
          $(this).find('.ginput_container').append($(this).find('.gfield_label'));
          $(this).find('.ginput_container input').addClass('form-control form-control-lg');
          $(this).find('.ginput_container select').addClass('form-control form-control-lg');

          // run the placeholder-shown function on each input
          $(this).find('.ginput_container input').each( placeholderShown ); // run once initially on page load
          $(this).find('.ginput_container input').bind( "change click blur input mouseenter", placeholderShown );
        });

      }

      var nonevalue = 'None of the Above';

      // add listeners
      $("input[type='checkbox']").not("[value='" + nonevalue + "']").change( ccForms.checkboxState );
      $("input[value='" + nonevalue + "']").change( ccForms.noneOfTheAboveState );
      // set current checkbox state state on page load
      $("input[type='checkbox']").not("[value='" + nonevalue + "']").each( ccForms.checkboxState );
      $("input[value='" + nonevalue + "']").each( ccForms.noneOfTheAboveState );


      $('.field-phone').find('input').mask("(999) 999-9999"); //set mask for phone number
      $('.field-zipcode').find('input').mask("99999"); // 1st zipcode - step 2a
      $('#form-locate-center-address').mask("99999"); // 2nd zipcode field that shows up under step 2b

      // Mobile/small devices: show next/submit buttons before previous
      if ($(window).width() < 768) {
        $('.gform_page_footer').each(function() {
          if ($(this).find('.gform_next_button').length && $(this).find('.gform_previous_button').length) {
            $(this).find('.gform_next_button').after($(this).find('.gform_previous_button'));
            $(this).find('.gform_next_button').wrapAll('<p>');
            $(this).find('.gform_previous_button').wrapAll('<p>');
          }

          if ($(this).find('input[type="submit"]').length && $(this).find('.gform_previous_button').length) {
            $(this).find('input[type="submit"]').after($(this).find('.gform_previous_button'));
            $(this).find('input[type="submit"]').wrapAll('<p>');
            $(this).find('.gform_previous_button').wrapAll('<p>');
          }
        });
      }

      if(currentPage == 2){
        ccForms.initDateTimeFieldPersonalInfoStep();
      }

      if(currentPage == 3){
        const agreetotermshtmloriginal = '<input name="input_17.1" type="checkbox" value="The individual is over the age of 18 and agrees to <a href=&quot;/terms/&quot; target=&quot;_blank&quot;>terms and conditions</a> and <a href=&quot;https://www.clearchoice.com/legal/privacy-policy/&quot; target=&quot;_blank&quot;>privacy policy</a>" id="choice_19_17_1" tabindex="33" style="height: 30px !important; width: 30px !important;" insp-form-input-id="field-agree-to-terms"><label for="choice_19_17_1" id="label_19_17_1" style="font-weight: 400;">The individual is over the age of 18 and agrees to <a href="/terms/" target="_blank">terms and conditions</a> and <a href="https://www.clearchoice.com/legal/privacy-policy/"; target=&quot;_blank&quot;>privacy policy</a></label>';
        $(".field-agree-to-terms .gchoice_19_17_1").html(agreetotermshtmloriginal);
        const agreetotermshtmlvariant = '<input name="input_17.1" type="checkbox" value="The individual is over the age of 18 and agrees to <a href=&quot;/terms/&quot; target=&quot;_blank&quot;>terms and conditions</a>" id="choice_34_17_1" tabindex="33" style="height: 30px !important; width: 30px !important;" insp-form-input-id="field-agree-to-terms"><label for="choice_34_17_1" id="label_34_17_1" style="font-weight: 400;">The individual is over the age of 18 and agrees to <a href="/terms/" target="_blank">terms and conditions</a></label>';
        $(".field-agree-to-terms .gchoice_34_17_1").html(agreetotermshtmlvariant);
      }

      // RESKINNING FOR SCHEDULE NOW
      if(currentPage == 3 && window.location.pathname.includes('schedule-now')){
        $(".container").addClass("schedNowReskin");
        $("#schedule-sub-header").remove();
        $(".schedule-form-container").addClass("schedule-now-form-container");
        $(".gform_body").addClass("gform_now_body");
        $(".form-page-instructions").addClass("form-now-page-instructions");
        $(".gform_next_button").addClass("gform_now_next_button");
        $(".gf_page_steps").addClass("gf_now_page_steps");
        $(".form-page-step").addClass("form-now-page-step");
        $("#schedule-form-container").attr('id', "schedule-now-form-container");
        $(".gf_step").addClass("gf_now_step");
        $(".gf_step_active").addClass("gf_step_active_now");
        $(".gf_step_pending").addClass("gf_step_pending_now");
        $(".gf_step_label").addClass("gf_now_step_label");
        $(".form-page-instructions").addClass("form-now-page-instructions");
        $("#twoMonthCalendarTemplate").addClass("schedNowReskin");
        $("#confirmation-details").addClass("schedNowReskin");
        $(".field-agree-to-terms").addClass("schedNowReskin");
        $(".gf_step_number").addClass("gf_now_step_number").append(".");
        $("#clearchoice-location-step3").html("<img class='clearchoiceLocationIcon' src='https://www.clearchoice.com/wp-content/uploads/2024/05/Vector.png'><div class='clearchoiceLocationLabel'>"+$("#clearchoice-location-step3").html()+"</div>");
        $(".address").addClass("address-now");
        $(".gfield_checkbox input").css("height","auto");
        $(".gfield_checkbox input").css("width","auto");
        $("#prospect-name").addClass("prospect-name-now");
        $("#agree-terms-line").remove();
        $("#instruction-header").addClass("instruction-header-now");
        $(".js-change-day").addClass("js-change-day-now");
        $(".js-change-time").addClass("js-change-time-now");
        $(".js-day-selection").addClass("js-day-selection-now");
        $(".js-time-selection").addClass("js-day-selection-now");
        ////
        $(".gform_next_button").addClass("gform_now_next_button");
        $("#patient-birthdate-text").addClass("patient-birthdate-text-now");
        $(".no-appts-number").addClass("no-appts-number-now");
        $(".gchoice_19_17_1 > label > a")[0].href = "https://smile.clearchoice.com/legal/terms/";
        var targetNode = document.getElementsByClassName('choose-date-timelist');
        var observer = new MutationObserver(function(){
            if(targetNode[0].className.includes('collapsed')){
              $('#instruction-header').fadeOut();
              $('.insert-after').css("margin-bottom","2%");
            }else{
              $('#instruction-header').fadeIn();
              $('.insert-after').css("margin-bottom","0");
            }
        });
        observer.observe(targetNode[0], { attributes: true, childList: true });

        $('#cancellation-phone-number').html('<a href="tel:8884863443">888-486-3443</a>');
        $("#calendar-phone-number").attr('href','tel:8884863443');
        $("#calendar-phone-number").html("888-486-3443");
      }
    });



    /**
     * Partial/vertical form stuff
     * - Validation
     */
    if ($('form.schedule-consultation-pre-form').length) {

      $('.field-zipcode input').prop('type', 'tel');


      // ajax for zipcode, sets custom validity on error
      var zipcodePartialAjax = function(el) {
        var $zipfield = typeof el !== 'undefined' ? el : $( '.field-zipcode input' );
        var zipVal = $zipfield.val();

        if (zipVal.length === 5 && $.isNumeric(zipVal)) {
          $.ajax({
            type: 'POST',
            url: ajaxurl,
            dataType: 'json',
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            data: {'zip':zipVal, 'action':'cc_salesforce_retrieve_ziptastic'},
            success: function(data) {
              if($.trim(data) == ''){
                // set custom validation for zipcode
                $zipfield[0].setCustomValidity("Please enter a valid ZIP Code.");
              }else{
                 //Auto-fill the city/state from zipcode
                 var $city = $('.field-city input');
                 var $state = $('.field-state select');

                 $city.val(data.city);
                 $city.click()

                 $state.val(data.state_short);
                 $state.click();
                 $zipfield[0].setCustomValidity("");
              }
            },

            error: function () {
              // set custom validation for zipcode
              $zipfield[0].setCustomValidity("Please enter a valid ZIP Code.");
            },

          });
        }
      };


      // run zipcode ajax when typing
      $( '.container' ).on( 'keyup change', '.field-zipcode input' , function() {
          zipcodePartialAjax( $(this) );
      } );


      // run once on init to check for any preset zipcodes
      zipcodePartialAjax();


      $('.schedule-consultation-pre-form input[aria-required="true"]').prop('required', true);

      // State input validation
      $('.field-state select').prop('required', true).attr('maxlength', '2');

      $('.schedule-consultation-pre-form').on('submit', function(event) {
        // disable button on submit so no resubmit occurs
        $(":input").unbind("blur");
        $('.gform_button[type="submit"]').attr("disabled", true);
        event.preventDefault();
        // if (!$('.schedule-consultation-pre-form')[0].checkValidity || $('.schedule-consultation-pre-form')[0].checkValidity()) {
        //   global.ccUserInfo = ccForms.getDefaultCCUserInfo();
        //   ccForms.setPreFormData($(this));
        //   ccForms.setGlobalFormData();

        //   // send leadData from Partial form to SF
        //   initiateSFInfoPartial();
        // }

      if($("#mobile-form").is(":visible")){
        global.ccUserInfo = ccForms.getDefaultCCUserInfo();
        global.ccUserInfo.firstName = $('#mobile-form .field-firstName input').val();
        global.ccUserInfo.lastName = $('#mobile-form .field-lastName input').val();
        global.ccUserInfo.phone = $('#mobile-form .field-phone input').val();
        global.ccUserInfo.email = $('#mobile-form .field-email input').val();
        global.ccUserInfo.address = $('#mobile-form .field-address input').val();
        global.ccUserInfo.zipcode = $('#mobile-form .field-zipcode input').val()
        global.ccUserInfo.city = $('#mobile-form .field-city input').val();
        global.ccUserInfo.state = $('#mobile-form .field-state select').val();
      }else{
        global.ccUserInfo = ccForms.getDefaultCCUserInfo();
        global.ccUserInfo.firstName = $('.field-firstName input').val();
        global.ccUserInfo.lastName = $('.field-lastName input').val();
        global.ccUserInfo.phone = $('.field-phone input').val();
        global.ccUserInfo.email = $('.field-email input').val();
        global.ccUserInfo.address = $('.field-address input').val();
        global.ccUserInfo.zipcode = $('.field-zipcode input').val();
        global.ccUserInfo.city = $('.field-city input').val();
        global.ccUserInfo.state = $('.field-state select').val();
      }


        var cookieData = {};
        cookieData.action = "processCookieData";
        cookieData.cookieData = JSON.stringify(global.ccUserInfo);
        cookieData.process = 'e';
        jQuery.ajax({
          url: ajaxurl,
          data: cookieData,
          cache: false,
          dataType: "json",
          type: "POST",
          success: function( result, success ) {
            if(Cookie.get('cc-pre-form')){
              Cookie.remove('cc-pre-form');
            }
            Cookie.set('cc-pre-form',result);
            ccForms.setGlobalFormData();
            // send leadData from Partial form to SF
            saveLeadInfoPartial();
            window['optimizely'] = window['optimizely'] || [];
            window['optimizely'].push({
              type: "event",
              eventName: "preFormNextClick",
            });
            // allow for return
            if($(".landing-page").length){
              setTimeout(function(){
                window.location.replace("/schedule-your-consultation/")
              },750);
            } else{
              setTimeout(function(){
                window.location.replace("/schedule-consultation/")
              },750);
            }
          },
          error: function( result, error ) {
            console.log( 'Error: ', result );
            ccForms.setGlobalFormData();
            // send leadData from Partial form to SF
            saveLeadInfoPartial();
            window['optimizely'] = window['optimizely'] || [];
            window['optimizely'].push({
              type: "event",
              eventName: "preFormNextClick",
            });
            // allow for return
            if($(".landing-page").length){
              setTimeout(function(){
                window.location.replace("/schedule-your-consultation/");

              },750);
            } else{
              setTimeout(function(){
                window.location.replace("/schedule-consultation/");

              },750);
            }
          },
        });
      });

    }

    /**
     * OS form stuff
     */
    if ($('form.schedule-consultation-form').length) {
      //694 test setup
      //if(testcookie)
      var newThemeWithProgressBar = Cookie.get("newThemeWithProgressBar");
      if(newThemeWithProgressBar){
        $("h1").css("color","#123057")
        $(".gform_body").addClass("newTheme");
        $(".form-page-step").hide();
        progressBar(1);
      }


       //dont set oath for preform
      setOathToken();
      global.ccUserInfo = ccForms.getDefaultCCUserInfo();
      ccForms.setGlobalFormData();

      if( global.ccUserInfo.guid != undefined){
        userId = global.ccUserInfo.guid;
      } else if( Cookie.get('GUID') != undefined ){
        userId = window.atob(Cookie.get('GUID'));
      }


      if(!global.switchedSteps){
        if(userId != undefined){
          global.dataLayer.push({
            'event': 'Form-Step-1',
            'lead_id': userId,
            });
        }else{
          global.dataLayer.push({
            'event': 'Form-Step-1',
            'lead_id': null,
           });
        }
      }else{
        global.dataLayer.push({ 'event': 'Form-Step-2' });
      }

      autofillPageGatherInfo();

      if(Cookie.get("page1data")){
        ccForms.readCookieDataAndSetData("page1data",1);
      }
      if(Cookie.get("page2data") && !Cookie.get("cc-pre-form")){
        ccForms.readCookieDataAndSetData("page2data",2);
      }
      //add disabled class
      $("#gform_next_button_19_11").addClass("disabledButton");

      $('input').change(function(){
        if(ccForms.checkSectionForComplete('page-few-questions')){
          $("#gform_next_button_19_11").removeClass("disabledButton");
          $("#gform_next_button_34_11").removeClass("disabledButton");
        }else{
          if(!$("#gform_next_button_19_11").hasClass("disabledButton")){
            $("#gform_next_button_19_11").addClass("disabledButton");
          }
        }
      });

      if(global.switchedSteps){
        $("#gform_next_button_34_11").addClass("disabledButton");
        $('input').change(function(){
          if(ccForms.checkSectionForComplete('page-gather-info')){
            $("#gform_next_button_34_11").removeClass("disabledButton");
          }else{
            if(!$("#gform_next_button_34_11").hasClass("disabledButton")){
              $("#gform_next_button_34_11").addClass("disabledButton");
            }
          }
        });
      }

      // add listener for checkbox on step 1
      $("body").on('change','input:checked', function () {
        var self = $(this);
        if (self.is(":checked")) {
          ccForms.addDentalQuestionToDataLayer(self[0]);
        }
      });
      $("#input_19_40").bind('keyup mouseup', function () {
        var self = $(this);
        if(self.val() != "" && self.val() != " " && self.val() != undefined && self.val() != null){
          global.dataLayer.push({"event":"leadform_substeps",
          step_number: "1.2.1",
          step_subtitle: "What Dental Solutions Do You Currently Have",
          checkbox_choice: self.val(),
        });
      }
      });

      if( global.ccUserInfo.guid != undefined){
        userId = global.ccUserInfo.guid;
      } else if( Cookie.get('GUID') != undefined ){
        userId = window.atob(Cookie.get('GUID'));
      }

      // add the iSpot pixel partial lead
      channel = getCampaignSource();
      var isPartialForm = Cookie.get('cc-pre-form');
      if(isPartialForm != undefined){
        if(userId != undefined && channel != undefined){
          addIspotPixel("partial_lead",channel,userId);
        }

      }
    }else if($(".pre-form").length){
      setOathToken();

      var highIntentTest = Cookie.get("highIntentCostTest");
      if(highIntentTest){
       initMenu();
       // add for partial mobile form for new form
       $("#high-intent-partial-form .field-address input").keyup(function (event) {
        var menu = $(".us-autocomplete-pro-menu");
        //if($("#data-street")[0].innerText) clearAddressData();
          var textInput = $("#high-intent-partial-form .field-address input").val();
          if (textInput.length > 0) {
            menu.show();
            getSuggestions(textInput);
          } else {
            menu.hide();
          }
      });
     }else{
       // add autocomplete to partial form pages
       initMenu();
       $(".field-address input").keyup(function (event) {
         var menu = $(".us-autocomplete-pro-menu");
         //if($("#data-street")[0].innerText) clearAddressData();
           var textInput = $(".layout__form-vertical--2col-form input#input_20_4").val();
           if (textInput.length > 0) {
             menu.show();
             getSuggestions(textInput);
           } else {
             menu.hide();
           }
       });
      // add for partial mobile form
      $("#mobile-form .field-address input").keyup(function (event) {
        var menu = $(".us-autocomplete-pro-menu");
        //if($("#data-street")[0].innerText) clearAddressData();
          var textInput = $("#mobile-form .field-address input").val();
          if (textInput.length > 0) {
            menu.show();
            getSuggestions(textInput);
          } else {
            menu.hide();
          }
      });
     }

      // add field blur event to dataLayer for partial form
      $(":input").blur(function(e) {
        var self = $(this);
        var label = $('label[for="' + self[0].id + '"]');
        var InputTarget =  $(e.relatedTarget).attr("id");
        if(InputTarget != undefined){
          if(InputTarget.includes('input')){
            if(label.length >= 0) {
              var parent = $(this).parent().parent().children(':first-child');
              var target = parent[0].innerText;
              if(target != "" && target != undefined && target != " " && target.length < 20){
                global.dataLayer.push({"event":"partialform_fields",
                  label_type: target,
                });
            }
            }
          }
        }
      });
    }
    /**
     *
     */
    $(document).bind('gform_page_loaded', function(event, form_id, current_page) {
      if ($('form.schedule-consultation-form').length) {
        if(global.page2loaded == undefined){
          global.page2loaded = false;
        }
        global.ccUserInfo.currentStep = current_page;

        var newThemeWithProgressBar = Cookie.get("newThemeWithProgressBar");
        //handle page 2 event loading twice
        if(current_page == 2 || current_page == "2"){
          if(!global.page2loaded && !global.switchedSteps){
            global.dataLayer.push({ 'event': 'Form-Step-' + current_page });
          }else if(!global.page2loaded){
            global.dataLayer.push({ 'event': 'Form-Step-' + 1 });
          }
          $(".gfield_label").append("*");
          global.page2loaded = true;
          //694 test setup
          if(newThemeWithProgressBar){
            $(".gform_body").addClass("newTheme");
            $(".form-page-step").hide();
            progressBar(2);
          }

        } else{
          if(global.switchedSteps && current_page == 1){
            global.dataLayer.push({ 'event': 'Form-Step-' + 2 });
          }else{
            global.dataLayer.push({ 'event': 'Form-Step-' + current_page });
          }

          //694 test setup
          //if(testcookie)
          if(current_page == 3 && newThemeWithProgressBar){
            $(".gform_body").addClass("newTheme");
            $(".make-appointment").prepend('<div class="formProgressBarContainer" style="text-align:center">');
            $(".form-page-step").hide();
            $(".form-page-header").hide();
            progressBar(3);
          }
        }

        //code for 695

        if(current_page==2){
          $("#gform_next_button_19_8").addClass("disabledButton");

          //run functionality to check if all full on changes
          $('input').change(function(){
            console.log("change");
            if(ccForms.checkSectionForComplete('page-gather-info')){
              $("#gform_next_button_19_8").removeClass("disabledButton");
            }else{
              if(!$("#gform_next_button_19_8").hasClass("disabledButton")){
                $("#gform_next_button_19_8").addClass("disabledButton");
              }
            }
          });
        }

        if(global.switchedSteps && current_page == 2){
          $("#gform_next_button_34_8").addClass("disabledButton");
          $('input').change(function(){
            if(ccForms.checkSectionForComplete('page-few-questions')){
              $("#gform_next_button_34_8").removeClass("disabledButton");
            }else{
              if(!$("#gform_next_button_34_8").hasClass("disabledButton")){
                $("#gform_next_button_34_8").addClass("disabledButton");
              }
            }
          });
          if(ccForms.checkSectionForComplete('page-few-questions')){
            $("#gform_next_button_34_8").removeClass("disabledButton");
          }
        }

        if(global.switchedSteps && current_page == 2){
          spanishLanguageTest();
          fewQuestionsStepAddLabels();
          fewQuestionsStepAnimateToTop();
          removeThirdQuestion();
        }
      }

      // Set default scrollTo position
      let scrollToPosition = $('.gform_wrapper form').offset().top - $('.banner').height();

      // Update the form scrollTo position if we're on mobile
      if (!window.matchMedia('(min-width: 576px)').matches) {
        // Scroll to the top of the OS form

        scrollToPosition = $('.gform_body').offset().top - $('.banner').height();
      }

      let currentPage = $('.gf_step.active');

      if (currentPage !== currentPagePrev) {
        if (current_page == 2 && global.ccUserInfo.step2Valid) {
          //$('html, body').animate({
          //  scrollTop: $('.form-locate-center').offset().top - $('.banner').height(),
          //}, 250);
          /**
          * Xandr pixel
          */
          // Conversion event
        } else {
          $('html, body').animate({
            scrollTop: scrollToPosition,
          }, 250);
        }
      }

      let currentPagePrev = $('.gf_step.active');

      // Scroll to top of form on error
      if ($('.gform_validation_error').length) {
        scrollToPosition = $('.gform_validation_error').offset().top - $('.banner');
        if(window.location.pathname.includes('colocation-schedule')){
          $( "#field_19_37").hide();
        }
        $('html, body').animate({
          scrollTop: scrollToPosition,
        }, 250);
      }
    });


    /**
     * Consultation pre-form
     * @todo: remove? not really sure what this is referencing -- .pre-form-horizontal
     */
    if ($('.pre-form-horizontal').length) {
      // Grid for address
      if (!$('.field-city').hasClass('col-lg-5 mb-0')) {
        $('.page-gather-info .gfield:not(:first)').wrapAll('<div class="row"><div class="col-sm-8"></div></div>');
        $('.field-city, .field-state, .field-zipcode').wrapAll('<div class="row"></div>');
        $('.field-city').addClass('col-lg-5 mb-0');
        $('.field-state').addClass('col-lg-4 mb-0');
        $('.field-zipcode').addClass('col-lg-3 mb-0');
        $('.page-gather-info .row li').addClass('mb-0');

        $('.field-firstName, .field-lastName').wrapAll('<div class="row"></div>');
        $('.field-firstName').addClass('col-lg-6 mb-0');
        $('.field-lastName').addClass('col-lg-6 mb-0');

        $('.field-phone, .field-email').wrapAll('<div class="row"></div>');
        $('.field-phone').addClass('col-lg-5 mb-0');
        $('.field-email').addClass('col-lg-7 mb-0');
      }

      // State input validation
      $('.field-state select').prop('required', true).attr('maxlength', '2');
    }

    // //show waiting icon for first step on submission
    // $('#gform_19').submit(function(){
    //     console.log('thing1');
    //     if($('#gform_page_19_1').attr('style') != 'display:none;' && $('.gfield_error').length == 0){
    //       console.log('thing2');
    //       showWaitingIcon('Please Wait');
    //     }
    // });

    /**
     * DO specific things on each page of the OS form
     * - Page 2 - Prefill form if data from Partial exist
     * - Page 2 - Logic/ajax for checking valid zipcode
     * - Page 3 - Retrieves available appts, runs initiateSFInfo()
     * - Page 3 - Displays available appts, display the appt times
     */

    if (!window.location.pathname.includes('/schedule-now') && window.location.pathname.includes('/schedule-consultation')) {
      $(".gfield_label").addClass("gfield_label_current");
      $(".gfield_description").addClass("gfield_description_current");
    }

    $(document).bind('gform_page_loaded', function(e, form_id, current_page) {

        // RESKINNING FOR SCHEDULE NOW
        if (window.location.pathname.includes('/schedule-now')) {
          $(".container").addClass("now-container");
          $("#schedule-sub-header").remove();
          $(".schedule-form-container").addClass("schedule-now-form-container");
          $(".gform_body").addClass("gform_now_body");
          $(".form-page-instructions").addClass("form-now-page-instructions");
          $(".gform_next_button").addClass("gform_now_next_button");
          $(".gf_page_steps").addClass("gf_now_page_steps");
          $(".form-page-step").addClass("form-now-page-step");
          $(".form-page-step-confirmation").addClass("form-now-page-step-confirmation");
          $("#schedule-form-container").attr('id', "schedule-now-form-container");
          $(".gf_step").addClass("gf_now_step");
          $(".gf_step_number").addClass("gf_now_step_number");
          $(".gf_step_active").addClass("gf_step_active_now");
          $(".gf_step_pending").addClass("gf_step_pending_now");
          $(".gf_step_label").addClass("gf_now_step_label");
          $(".field-language").css("display","none");
          $(".gfield_label").addClass("gfield_now_label");
          $(".gfield_description").addClass("gfield_now_description").append(".");
          $(".ginput_container_checkbox").addClass("ginput_now_container_checkbox");
          $(".gform_wrapper form ul.gform_fields:not(.top_label) li.gfield_html_formatted").css({"margin-left": "0 !important"})
          $(".field-hearing-question").addClass("field-now-hearing-question");
          $("#gfield_description_19_33").addClass("gfield_now_description_multiple");
          $("#choice_19_37_1").css({"vertical-align": "top", "margin-top": "5px"});
          $("#field_19_38").css({"padding": "30px 5px 0px", "text-align": "center"});
          $("#gform_page_19_2").addClass("gform_page_19_2_now");
          $("#input_19_46").addClass("schedNowReskin");
          document.querySelectorAll("label").forEach(label => {label.classList.add("label-now")});
          document.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {checkbox.classList.add("checkbox-now")});
        }


      console.log('current_page', current_page);
      // Consultation form
      if ($('form.schedule-consultation-form').length) {
        $('form.schedule-consultation-form').find('input[type=text]').addClass('inspectletIgnore');

        // change zipcode input type to 'tel' for keypad on mobile
        $('.field-zipcode input').prop('type', 'tel');


        // when using the zipcode field
        $( '.container' ).on( 'keyup change', '.field-zipcode input' , function() {
          var $zipfield = $( this );
          var zipVal = $zipfield.val();

          if ( zipVal.length === 5 && $.isNumeric( zipVal ) ) {
            $.ajax({
              type: 'POST',
              url: ajaxurl,
              dataType: 'json',
              contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
              data: {'zip':zipVal, 'action':'cc_salesforce_retrieve_ziptastic'},
              success: function(data) {
                if($.trim(data) == ''){
                  if( is__w1){
                    $zipfield.addClass('error zipcode-error');
                  }
                  else{
                    $zipfield.parents('.gfield').addClass('gfield_error zipcode-error');
                  }
                }else{
                   //Auto-fill the city/state from zipcode
                   var $city = $('.field-city input');
                   var $state = $('.field-state select');

                   $city.val(data.city);
                   $city.click()

                   $state.val(data.state_short);
                   $state.click();
                   if( is__w1){
                     $zipfield.removeClass('error zipcode-error');
                   }
                   else{
                     $zipfield.parents('.gfield').removeClass('gfield_error zipcode-error');
                   }
                }
              },

              error : function() {
                if( is__w1)
                  $zipfield.addClass('error zipcode-error');
                else
                  $zipfield.parents('.gfield').addClass('gfield_error zipcode-error');
              },

            });
          }


        } );


        initMenu();
        $(".field-address input").keyup(function (event) {
        var menu = $(".us-autocomplete-pro-menu");
        //if($("#data-street")[0].innerText) clearAddressData();
          var textInput = $(".field-address input").val();
          if (textInput.length > 0) {
            menu.show();
            getSuggestions(textInput);
          } else {
            menu.hide();
          }
        });


        if (current_page == 2) {

          // RESKINNING FOR SCHEDULE NOW
          if (window.location.pathname.includes('/schedule-now')) {
            $(".gf_now_step_number").append(".");
          }

          if(window.location.pathname.includes('colocation-schedule')){
            $('#webform-component-step-find-center--fieldset-header > h4').text("Please provide the patient information below.");
            $( "#gf_step_19_2 > span.gf_step_label" ).text("PATIENT INFORMATION");
          }

          hideWaitingIcon();

          if (global.ccUserInfo.firstName) {
            $('.field-firstName input').val(global.ccUserInfo.firstName);
          }

          if (global.ccUserInfo.lastName) {
            $('.field-lastName input').val(global.ccUserInfo.lastName);
          }

          if (global.ccUserInfo.phone) {
            $('.field-phone input').val(global.ccUserInfo.phone);
          }

          if (global.ccUserInfo.email) {
            $('.field-email input').val(global.ccUserInfo.email);
          }

          if (global.ccUserInfo.address) {
            $('.field-address input').val(global.ccUserInfo.address);
          }

          if (global.ccUserInfo.zipcode) {
            $('.field-zipcode input').val(global.ccUserInfo.zipcode);
          }

          if (global.ccUserInfo.city) {
            $('.field-city input').val(global.ccUserInfo.city);
          }

          if (global.ccUserInfo.state) {
            $('.field-state select').val(global.ccUserInfo.state);
          }

          if(global.ccUserInfo.birthDate){
            $('.field-birth-date-entry input').val(global.ccUserInfo.birthDate);
          }

          if(ccForms.checkSectionForComplete('page-gather-info')){
            $("#gform_next_button_19_8").removeClass("disabledButton");
          }

          // add field tracking to full form, only if not comming from partial form
          if($('.field-firstName input').val() == ""){
          // add field blur event to dataLayer
            $(":input").blur(function(e) {
              var self = $(this);
              var label = $('label[for="' + self[0].id + '"]');
              var InputTarget =  $(e.relatedTarget).attr("id");
              if(InputTarget != undefined){
                if(InputTarget.includes('input')){
                  if(label.length >= 0) {
                    var parent = $(this).parent().parent().children(':first-child');
                    var target = parent[0].innerText;
                    if(target != "" && target != undefined && target != " " && target.length < 20){
                      global.dataLayer.push({"event":"fullform_fields",
                        label_type: target,
                      });
                    }
                  }
                }
              }
            });
          }

          // Grid for address
          if (!$('.field-city').hasClass('col-lg-5')) {

            // wraps everything in row/col divs for w2
            if(is__w2)
              $('.page-gather-info .gfield:not(:first)').wrapAll('<div class="row"><div class="col-sm-8"></div></div>');

            $('.field-city, .field-state, .field-zipcode').wrapAll('<div class="row"></div>');
            $('.field-city').addClass('col-lg-5');
            $('.field-state').addClass('col-lg-4');
            $('.field-zipcode').addClass('col-lg-3');
            $('.page-gather-info .row li').addClass('mb-0');

            $('.field-phone, .field-email').wrapAll('<div class="row"></div>');
            $('.field-phone').addClass('col-lg-5');
            $('.field-email').addClass('col-lg-7');
          }

          // State input validation
          $('.field-state select').prop('required', true).attr('maxlength', '2');

          // Show lookup center form if personal info is valid
          if ($('.gform_wrapper').hasClass('gform_validation_error')) {
            global.ccUserInfo.step2Valid = false;
          }

          if (global.ccUserInfo.step2Valid) {
            if (!$('.form-locate-center').length && !global.switchedSteps) {

              $('.page-gather-info .gform_page_footer').html(global.locateCenterForm);
              global.dataLayer.push({ 'event': 'Form-Step-1.5' });
              // tweak the locate center form to hide certain things
              if( is__w1 ) {
                // 100% width the `select a center` h2
                $('.page-gather-info .form-locate-center .col-sm-8').attr('class', 'col-12 form-locate-center');
                $('.page-gather-info .form-locate-center .col-sm-4').attr('class', 'd-none');
                // 100% width the center results
                $('.page-gather-info .form-locate-center-results .col-sm-7').attr('class', 'col-12 nearest-location-container');
                // hide the googlemaps
                $('.page-gather-info .form-locate-center-results .col-sm-5').addClass('d-none');
                // hide the zipcode stuff
                $('.page-gather-info .form-locate-center-lookup').addClass('d-none');
              }

              // mobile needs a bit more room on scroll
              if ($(window).width() < 768) {
                $('html, body').animate({
                  scrollTop: $('.page-gather-info .form-group.bg-light').offset().top + 250,
                }, 250);
              } else {
                $('html, body').animate({
                  scrollTop: $('.page-gather-info .form-group.bg-light').offset().top,
                }, 250);
              }
            }

            // RESKINNING FOR SCHEDULE NOW
            if (window.location.pathname.includes('schedule-now')) {
              $('#select-a-center-header').addClass('select-a-center-header-now');
            }

            // If not coming from page 3 (location isn't yet selected), hide next button
            if (!global.ccUserInfo.centerID && !global.switchedSteps) {
              $('.gform_next_button').hide();
            }
          }

          let formID = $('input[name="gform_submit"]').val();
          $('#gform_target_page_number_'+formID).val(2);
          $('.gform_next_button').attr('onclick', 'jQuery("#gform_target_page_number_'+formID+'").val("2");  jQuery("#gform_'+formID+'").trigger("submit",[true]);');
          $('.gform_next_button').attr('onkeypress', 'jQuery("#gform_target_page_number_'+formID+'").val("2");  jQuery("#gform_'+formID+'").trigger("submit",[true]);');
          // $('.gform_next_button').addClass('form-locate-center-lookup');

          $('#form-locate-center-address').keydown(function(e) {
            if (e.keyCode == 13) {
              e.preventDefault();
              $.when(global.retrieveLeadInfoLock).then(function(success){
                if(success && ccForms.checkChangesToPreformData()){
                  saveUpdateLeadInfoAndRetrieveInfo();
                }else{
                  //delete preform info in case they just changed data
                  Cookie.remove('cc-pre-form');
                  Cookie.remove('userFrontEndId');
                  saveLeadInfo();
                }
              });
              console.log('test1');
              if(!global.switchedSteps){
                lookupCenter();
              }


              return false;
            }
          });

          if (global.ccUserInfo.zipcode) {
            $('#form-locate-center-address').val(global.ccUserInfo.zipcode);
          }

          if($('#form-locate-center-address').val() != undefined && $('#form-locate-center-address').val() != ''){
            $.when(global.retrieveLeadInfoLock).then(function(success){
              if(success && ccForms.checkChangesToPreformData()){
                saveUpdateLeadInfoAndRetrieveInfo();
              }else{
                saveLeadInfo();
              }
            });
            if(!global.switchedSteps){
              if(((getParam('Center__c') != "" || getParam('Center__c') != null) && (getParam('trans_id') == 'coloxfer') == true)){
                $('.form-locate-center').addClass('d-none');
                $('.nearest-location-container').addClass('d-none');
                showWaitingIcon('Please Wait');
              }else{
                showWaitingIcon('Retrieving your nearest ClearChoice Centers');
              }
              lookupCenter();
            }
          }



          if (!$('.validation_error').length) {
            // $('.page-gather-info .gform_page_fields').addClass('d-none');
          } else {
            if (typeof zip !== 'undefined') {
              $('#form-locate-center-address').val(zip);
            }

            $('.form-locate-center-lookup .btn').trigger('click');
          }
          if(!global.switchedSteps){
            $('a[href="#form-locate-center-form"]').click(function(e) {
              e.preventDefault();
              $('.gform_next_button').hide();
              $('.form-locate-center-results').addClass('d-none');
              $('.form-locate-center-results .list-group').empty();
              $('.form-locate-center-lookup').removeClass('d-none');
              $('.form-locate-center > .row .text-muted').removeClass('d-none');
              $('.form-locate-center-lookup input[type="text"]').focus();
              $('.page-gather-info .gform_page_fields').addClass('d-none');
            });
          }
        }

        if (current_page == 3) {
          if(window.location.pathname.includes('colocation-schedule')){
            $( "#gf_step_19_2 > span.gf_step_label" ).text("PATIENT INFORMATION");
          }

          $('.gform_button[type="submit"]').hide();
          $('.field-agree-to-terms').hide();
          /** not needed if statement
          if (global.ccUserInfo.availableAppointmentsRetrieved) {
            if (global.ccUserInfo.firstAvailableDate) {
              displayAvailableAppointments();
              window.scrollTo(0,300);

              // Scroll to top of form on error
              var targetNode = document.getElementById('webform-component-messages');
              var observer = new MutationObserver(function(){
                  if(targetNode.style.display != 'none'){
                    window.scrollTo(0,300);
                  }
              });
              observer.observe(targetNode, { attributes: true, childList: true });
            } else {
              $('.no-appointments').html('<div class="alert alert-danger">There aren\'t any appointments available. Please call ' + callCenterNumber + ' for assistance.</div>');
            }
          } else {
          */
            if (!$('.loading-appointments').length) {
              console.log("testpage3flow3");
              $('.no-appointments').before('<li class="gfield loading-appointments mb-4"></li>');
              $.when(global.saveLeadInfoLock).then(function(success){
                if(success){
                  retrieveAppointments();
                  // Scroll to top of form on error
                  var targetNode = document.getElementById('webform-component-messages');
                  var observer = new MutationObserver(function(){
                      if(targetNode.style.display != 'none'){
                        window.scrollTo(0,300);
                      }
                  });
                  observer.observe(targetNode, { attributes: true, childList: true });
                }else{
                  $('#webform-messages').html('<div class="alert alert-danger" role="alert">There was an issue connecting to our servers. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');
                }
              });
              if(global.saveLeadInfoDone !== undefined && global.saveLeadInfoLock.state() != "resolved"){
                global.saveLeadInfoLock.resolve(global.saveLeadInfoDone);
              }
              if(global.saveLeadInfoDone !== undefined && global.saveLeadInfoLock.state() == "resolved" &&  !Cookie.get("scheduleTest")){
                retrieveAppointments();
              }
            }
          //}









          /**
           * Step 3 - Click on Calendar date, display times
           */

          if(is__w2) {
          $(document).on('click', '#webFormCalendar', function() {
            var $selectObject = $('#choose-date--timelist .btn-group-grid');
            if ($(this).find('.selected').length > 0) {
              var dateInfo = $('#webFormCalendar .selected').data();

              $selectObject.empty();

              // Display the correct times
              $.each(global.ccUserInfo.availableAppointments.seminars, function(index, time) {
                if (time.date === dateInfo.year + '-' + dateInfo.month + '-' + dateInfo.day) {
                  var offset = getTimeZoneOffsets(global.ccUserInfo.availableAppointments.timezone, global.ccUserInfo.availableAppointments.timezone);
                  var myDate = getActualTime(dateInfo.year, dateInfo.month, dateInfo.day, time.start, offset);
                  let myDateText = printActualTime(myDate);

                  var filledClass = ' class="btn btn-white" ';
                  if (time.blocks.length === 0) {
                    filledClass = ' class="btn btn-white disabled slot-filled" ';
                  }

                  $selectObject.append(
                    $('<div' + filledClass + '></div>')
                      .attr({
                        "data-timeslot": time.blocks.join('.'),
                        "data-datestring": moment(myDate).format('l'),
                        "data-timestring": moment(myDate).format('h:mm A'),
                        "data-calendarstart": moment(myDate).format('YYYY-MM-DD HH:mm:ss'),
                        "data-calendarend": moment(myDate).add(1, 'h').format('YYYY-MM-DD HH:mm:ss'),
                      })
                      .html(myDateText + '<br class="time-zone-break"> ' + global.ccUserInfo.availableAppointments.timezone));
                }
              });
              if($(window).width() <= 767){
                $([document.documentElement, document.body]).animate({
                    scrollTop: ($(".select-time").offset().top -20),
                }, 500);
              }
            } else {
              global.ccUserInfo.appointment = null;
            }
          });

          // click the available appoint time slots
          $(document).on('click', '#choose-date--timelist .choose-date-timelist .btn-group > .btn:not(.disabled)', function() {
            var $this = $(this);

            global.ccUserInfo.calendarStart = $this.attr('data-calendarstart');
            global.ccUserInfo.calendarEnd = $this.attr('data-calendarend');

            $('#choose-date--timelist .choose-date-timelist .btn-group > .btn').removeClass('selected');
            $('#choose-date--timelist .choose-date-timelist .btn-group > .btn').removeClass('active');
            $this.addClass('selected');
            $this.addClass('active');

            if (global.ccUserInfo.appointment === '' || global.ccUserInfo.appointment === null) {
              let scheduledNotConfirmed = {};
              scheduledNotConfirmed.GUID = global.ccUserInfo.guid;
              scheduledNotConfirmed.isContact = (global.ccUserInfo.contactID !== '' && global.ccUserInfo.contactID !== null) ? true : false;
              if(global.ccUserInfo.page == 'adi' ) {
                scheduledNotConfirmed.TransId = global.ccUserInfo.trans_id;
                scheduledNotConfirmed.referral_id = global.ccUserInfo.referral_id;
              } else {
                scheduledNotConfirmed.TransId = 'onlinescheddateandtime';
              }
              scheduledNotConfirmed.action = 'cc_salesforce_submit_campaign_member';
              scheduledNotConfirmed.securityToken = global.securityToken;
              $.ajax({
                url: ajaxurl,
                data: scheduledNotConfirmed,
                cache: false,
                dataType: 'json',
                type: 'POST',
              });
            }

            global.ccUserInfo.appointment = $this.data('timeslot');
            global.ccUserInfo.appointmentDate = $this.data('datestring');
            global.ccUserInfo.appointmentTime = $this.data('timestring');

            global.ccUserInfo.appointmentHtmlInfo = "class = " + $this.attr("class") + " \ntimeslot = " + $this.data('timeslot') +
              "\ndatestring = " + $this.data('datestring') + "\ntimestring = " + $this.data('timestring') +
              "calendarstart = " + $this.data('calendarstart') + "\ncalendarend = " + $this.data('calendarend');
            global.ccUserInfo.appointmentTimestring = $this.data('calendarstart');

            $('.gform_button[type="submit"]').attr("disabled", false);

            $('.field-agree-to-terms').show();
            $('.gform_button[type="submit"]').show();
            if($(window).width() <= 950){
              $([document.documentElement, document.body]).animate({
                  scrollTop: ($(".field-agree-to-terms").offset().top - 20),
              }, 500);
            } else{
              $([document.documentElement, document.body]).animate({
                  scrollTop: ($(".field-agree-to-terms").offset().top - 100),
              }, 500);
            }
          });

          $(document).on('click', '.gform_button[type="submit"]', function(e) {
            e.preventDefault();
            if(!$('.field-agree-to-terms input').is(':checked')){
              $('#webform-messages').html('<div class="alert alert-danger" role="alert">Please validate that you are 18 or older.</div>');
              $('li.field-agree-to-terms').addClass('gfield_error');
              return false;
            } else{
              $('#webform-messages').html('');
              $('li.field-agree-to-terms').removeClass('gfield_error');
            }

            global.ccUserInfo.attemptsAtSubmission = 0;
            submitForm();
            //confirmationPageInit();
          });

          // w1: run all the functions to handle w1's calendar/times
          } else {
              var newOneMonthSched = Cookie.get("newOneMonthSched");
              if(newOneMonthSched){
                w1_step3();
              }else{
                w1_step3_2month();
                initiateStep3TwoMonthCal();
              }


              // w1_step3_2month();
              // initiateStep3TwoMonthCal();
              if(window.location.pathname.includes('colocation-schedule')){
                $( "#instruction-header")[0].innerHTML = 'Please select the earliest appointment date and time on the calendar below,<br>and click confirm to book your consultation.';
                $( "#clearchoice-location-step3")[0].innerHTML = 'Selected ClearChoice location:';
                $( ".tooltipstered").hide();
                $('#patient-birthdate-text').parent().nextUntil(':not(br)').remove();
                $('.no-appts-number').hide();
              }

          }
        }

        // run the zipcode validator once on page load, run it after everything, so the inputs get filled
        var $zipfield = $('.field-zipcode input');


        if ( $zipfield.val().length === 5 && $.isNumeric( $zipfield.val() ) && $(".field-zipcode").is(":visible")) {
          $.ajax({
            type: 'POST',
            url: ajaxurl,
            dataType: 'json',
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            data: {'zip':$zipfield.val(), 'action':'cc_salesforce_retrieve_ziptastic'},
            success: function(data) {
              if($.trim(data) == ''){
                $zipfield.parents('.gfield').addClass('gfield_error zipcode-error');
              }else{
                $zipfield.parents('.gfield').removeClass('gfield_error zipcode-error');
              }
            },

            error: function () {
              $zipfield.parents('.gfield').addClass('gfield_error zipcode-error');
            },

          });
        }



      }
    });

    //for switched form flow ab test where two forms will load on the page causing an issue
    var preventDoubleLoadFirstPageLoadCount = 0;
    /**
     *
     */
    $(document).bind('gform_post_render', function(event,formid,currentPage) {

      if(currentPage == 1){
        if(preventDoubleLoadFirstPageLoadCount > 0){
          return;
        }
        preventDoubleLoadFirstPageLoadCount++;
      }

      // Inspectlet data attributes
      inspectletDataAttributes();


      /*  // Add message area after steps
      $('.gform_body').prepend('<div id="webform-messages"></div></div>');
      $('.schedule-consultation-form_wrapper').prepend('\
        <div id="web-form-modal" class="modal fade">\
          <div class= "modal-dialog modal-md modal-dialog-centered">\
            <div class="modal-content">\
              <div class="modal-body">\
                <div class="row">\
                  <div class="col-sm-12">\
                    <div class="message-text text-center">\
                    </div>\
                  </div>\
                </div>\
                <div class="buttons">\
                </div>\
              </div>\
            </div>\
          </div>\
        </div>');


      //add cancelation form modal
      $('.schedule-consultation-form_wrapper').prepend('\
        <div id="call-me-now-form" class="modal fade">\
          <div class= "modal-dialog modal-md modal-dialog-centered">\
            <div class="modal-content">\
              <div class="modal-body">\
                <div class="row">\
                  <div class="message-text text-center">\
                    <p>Verify your information here below. Then, press continue. A ClearChoice representative will call you shortly<p>\
                  </div>\
                </div>\
                <div class="row">\
                  <div class="call-me-now-form">\
                    <label>First Name</label>\
                    <input class="required" id="call-me-now-firstName" title="First Name" type="text" >\
                    <label>Last Name</label>\
                    <input class="required" id="call-me-now-lastName" title="Last Name" type="text">\
                    <label>Phone Number</label>\
                    <input class="required" id="call-me-now-phone" title="Phone" type="tel">\
                  </div>\
                </div>\
                <div class="buttons">\
                  <div class="row"><button class="continue btn btn-sm btn-outline-primary px-3 py-2 temp">Continue</button></div>\
                  <div class="row"><button class="cancel btn btn-sm btn-outline-primary px-3 py-2 temp">Cancel</button></div>\
                </div>\
              </div>\
            </div>\
          </div>\
        </div>');
      */


      // Move descriptions below labels (w2 only)
      if( is__w2 ) {
        $('.gfield_description').each(function(i, e) {
          const fieldDescription = $('<div>').append($(e).clone()).remove().html();
          $(e).siblings('label.gfield_label').after(fieldDescription);
          $(e).remove();
        });
      }

      // Consultation form
      if ($('form.schedule-consultation-form').length) {
        $('form.schedule-consultation-form').find('input[type=text]').addClass('inspectletIgnore');
        if (Cookie.get('GUID') && Cookie.get('GUID') !== '') {
          global.ccUserInfo.guid = window.atob(Cookie.get('GUID'));
        }

        // if ($('.number-of-years').length) {
        //   $('.number-of-years').appendTo('.current-dental-solutions .gfield_checkbox li:first label');
        //   $('.number-of-years input[type="text"]').prop('type', 'hidden');
        //   $('.number-of-years .ginput_container').after(`
        //     <div class="btn-group" data-toggle="buttons">
        //       <label class="btn btn-white">
        //         <input type="radio" name="options" autocomplete="off"> 0 - 6 mo
        //       </label>
        //       <label class="btn btn-white">
        //         <input type="radio" name="options" autocomplete="off"> 7 mo - 2 yrs
        //       </label>
        //       <label class="btn btn-white">
        //         <input type="radio" name="options" autocomplete="off"> 3 - 5 yrs
        //       </label>
        //       <label class="btn btn-white">
        //         <input type="radio" name="options" autocomplete="off"> 6 - 10 yrs
        //       </label>
        //       <label class="btn btn-white">
        //         <input type="radio" name="options" autocomplete="off"> 11+  yrs
        //       </label>
        //     </div>
        //   `);
        // }
        $('.schedule-consultation-form').on('submit', function(e) {
          //validation modal popup code
          var theForm = $( 'form.schedule-consultation-form' );
          var formID = theForm.find("input[name='gform_submit']").val();
          var currentPageField = $("#schedule-your-free-consultation-form-container input[name='gform_source_page_number_" + formID + "']");
          var curstep = currentPageField.val();
          var stepClasses = $("#gform_page_" + formID + "_" + curstep).attr("class");
          var stepName = stepClasses.split(" ")[1];
          var errors = '';
          var errorHTML = '';
          var messageBox = $("#webform-component-messages");

          errors = ccForms.validateSectionInlineMessaging(stepName);
          //   original line of code, changes for task WD-651 consider cleanup of orginal function if we leave this code
          //   errors = ccForms.validateSection(stepName);


          //code change for WD-651
          //some logic for comm preference checkbox

          // if( errors.length > 0){
          //   //Fill the message box with the errors
          //   errorHTML = '<h4>Please fix the following:</h4>';
          //   for (var i = 0; i < errors.length; i++) {
          //     errorHTML += "<p>" + errors[i] + "</p>";
          //   }



          //   messageBox.find(".message-text").html(errorHTML);

          //   ccForms.showMessageBox();
          //   $('.dismiss').click(function(){
          //     ccForms.hideMessageBox();
          //   });
          //   return false;
          // }


          showWaitingIcon('Please Wait');
          if ($('.field-questions').length) {
            showWaitingIcon('Please Wait');
            //change for WD-651
            if(errors.length > 0){
              e.preventDefault();
            }


            // if ($('.number-of-years .btn-group .active').length) {
            //   $('.number-of-years input[type="hidden"]').val($('.number-of-years .btn-group .active').text().trim());
            // }

            // reset the questions between calls in case they changed
            global.ccUserInfo.fewQuestions = {};


            $('.field-questions input[name!="options"]').each(function() {
              if ($(this).is(':checked')) {
                global.ccUserInfo.fewQuestions[$(this).val()] = true;
              }

              if ( $(this).is('input[type=number]') && $(this).val() ) {
                const numberLabel = $('label[for="' + this.id + '"]').text();
                global.ccUserInfo.fewQuestions[numberLabel] = $(this).val();
              } else if ( !$(this).is(':checkbox') && $(this).val() ) {
                const inputLabel = $('label[for="' + this.id + '"]').text();
                global.ccUserInfo.fewQuestions[inputLabel] = $(this).val();
              }
            });

            //WD-703  spanish language input
            $('.field-language input[name!="options"]').each(function() {
              if ($(this).is(':checked')) {
                global.ccUserInfo.spokenLanguage = $(this).val();
              }
            });

            var fewQuestionsData = [];
            $('.field-questions input[name!="options"]').each(function(index) {
              if ($(this).is(':checked')) {
                fewQuestionsData[index] = 'checked';
              }

              if ( $(this).is('input[type=number]') && $(this).val() ) {
                fewQuestionsData[index] = $(this).val();
              } else if ( !$(this).is(':checkbox') && $(this).val() ) {
                fewQuestionsData[index] = $(this).val();
              }
            });
            ccForms.processAndSaveCookieData(JSON.stringify(fewQuestionsData),"page1data");
            hideWaitingIcon();

            //firing on wrong step currently. Need to find a better way to handle this
            if(currentPage == 2 && errors.length == 0 && global.switchedSteps){
              e.preventDefault();
              showCenterSelectionStep();
            }
          }

          global.ccUserInfo.availableCenters = global.ccUserInfo.availableCenters;
          global.ccUserInfo.centerID = global.ccUserInfo.centerID;

          if ($('.field-firstName input').val()) {
            global.ccUserInfo.firstName = $('.field-firstName input').val();
            global.ccUserInfo.lastName = $('.field-lastName input').val();
            global.ccUserInfo.phone = $('.field-phone input').val();
            global.ccUserInfo.email = $('.field-email input').val();
            global.ccUserInfo.address = $('.field-address input').val();
            global.ccUserInfo.zipcode = $('.field-zipcode input').val();
            global.ccUserInfo.city = $('.field-city input').val();
            global.ccUserInfo.state = $('.field-state select').val();
            global.ccUserInfo.birthDate = $( '.field-birth-date-entry input' ).val();

          var tempUserData = {};
          tempUserData.firstName = $('.field-firstName input').val();
          tempUserData.lastName = $('.field-lastName input').val();
          tempUserData.phone = $('.field-phone input').val();
          tempUserData.email = $('.field-email input').val();
          tempUserData.address = $('.field-address input').val();
          tempUserData.zipcode = $('.field-zipcode input').val();
          tempUserData.city = $('.field-city input').val();
          tempUserData.state = $('.field-state select').val();
          tempUserData.birthDate = $( '.field-birth-date-entry input' ).val();
          ccForms.processAndSaveCookieData(JSON.stringify(tempUserData),'page2data');



            // validate zipcode/state and show modal if invalid
            var modal_text = '';
            var show_modal = false;



            if (validStates.indexOf($('.field-state select').val()) === -1) {
                show_modal = true;
                modal_text += '<p class="mb-0 text-center">Please enter a valid state.</p>';
            }


            if( $('.field-zipcode').hasClass('zipcode-error') ) {
              show_modal = true;
              modal_text += '<p class="mb-0 text-center">Please enter a valid zipcode.</p>';
            }

            // show modal if validation fails
            //code change for WD-651
            // if( show_modal) {
            //   e.preventDefault();
            //   $('#modal-message .modal-body').html(modal_text);
            //   $('#modal-message').modal('show');
            //   return false;
            // }



            if (global.ccUserInfo.firstName && global.ccUserInfo.lastName && global.ccUserInfo.phone && global.ccUserInfo.email && global.ccUserInfo.address && global.ccUserInfo.zipcode && global.ccUserInfo.city && global.ccUserInfo.state) {

              if(global.switchedSteps && errors.length == 0 && currentPage == 1){
                global.ccUserInfo.step2Valid = true;
                saveLeadCall();
              }
              if(!global.switchedSteps && errors.length == 0 && currentPage == 2){
                global.ccUserInfo.step2Valid = true;
              }
            }
          }
        });
      } // End of consultation form
    });





    /**
     * Confirmation page of OS form
     * - Calendar/appt details
     * - Sets directions to google maps
     */
    $( document ).bind( 'gform_confirmation_loaded', function( event, form_id ) {

      $( ".form-page-instructions" ).show();

      // code to be trigger when confirmation page is loaded
      var leadTier;
      global.dataLayer.push({'timesPromptedOnDateClick':global.ccUserInfo.numberOfPrompts});
      if(global.ccUserInfo.leadSegment20 <= 20 && global.ccUserInfo.leadSegment20 >=15){
        leadTier = 'T1';
      }else if(global.ccUserInfo.leadSegment20 <= 14 && global.ccUserInfo.leadSegment20 >= 8){
        leadTier = 'T2';
      }else{
        leadTier = 'T3';
      }

      if( global.ccUserInfo.guid != undefined){
        global.dataLayer.push({
        'event': 'Form-Step-confirmation',
        'lead_id': global.ccUserInfo.guid,
        'lead_tier' : leadTier,
        'lead_segment' : global.ccUserInfo.leadSegment20,
        });
      }else if( Cookie.get('GUID') != undefined ){
        userId = window.atob(Cookie.get('GUID'));
        global.dataLayer.push({
          'event': 'Form-Step-confirmation',
          'lead_id': userId,
          'lead_tier' : leadTier,
          'lead_segment' : global.ccUserInfo.leadSegment20,
          });
      }

      // add the iSpot pixel for form confirmation
      if( global.ccUserInfo.guid != undefined){
        userId = global.ccUserInfo.guid;
      } else if( Cookie.get('GUID') != undefined ){
        userId = window.atob(Cookie.get('GUID'));
      }
      // add the iSpot pixel for schedule complete
      channel = getCampaignSource();
      addIspotPixel("schedule_complete",channel,userId);
      // add floodlight counter for schedule complete leads
      if(global.ccUserInfo.leadSegment20 != undefined){
        if(global.ccUserInfo.leadSegment20 <= 20 && global.ccUserInfo.leadSegment20 >=15){
          global.dataLayer.push({ 'event': 'schedule_tier_floodlight', 'cat': 'tier10' });
        }else if(global.ccUserInfo.leadSegment20 <= 14 && global.ccUserInfo.leadSegment20 >= 8){
          global.dataLayer.push({ 'event': 'schedule_tier_floodlight', 'cat': 'tier20' });
        }else{
          global.dataLayer.push({ 'event': 'schedule_tier_floodlight', 'cat': 'tier30' });
        }
      }




      /*
      var showHighlightedDays = Cookie.get('testHighlightedScheduleDays');
      var v;
      if((showHighlightedDays && typeof showHighlightedDays != 'undefined')){
        v = "v1";
      }else {
        v = "v0";
      }
      */
      //optimizely custom event for clicking on any highlighted calendar time
      window['optimizely'] = window['optimizely'] || [];
      window['optimizely'].push({
        type: "event",
        eventName: "lead_online_schedule",
        tags: {
          revenue: 0, // Optional in cents as integer (500 == $5.00)
          value: 0.00, // Optional as float
        },
      });

      // add fix for animation
      // RESKINNING FOR SCHEDULE NOW
      if (!window.location.pathname.includes('schedule-now')) {
        $(".text-accent-primary").get(0).scrollIntoView();
      }
      if (window.location.pathname.includes('schedule-now')) {
        $(".confirmation-test").addClass("confirmation-test-now");
        $(".confirmation-top-text").addClass("confirmation-top-text-now");
        const appointmentHeadHTML ='<p class="confirmation-top-head-now">Congratulations on Scheduling Your Consultation</p>';
        const appointmentSubHTML = '<p class="confirmation-top-sub-now">Your appointment has been scheduled</p>';
        $(".confirmation-top-bar").addClass("confirmation-top-bar-now");
        $(".confirmation-top-bar").append(appointmentHeadHTML);
        $(".confirmation-top-bar").append(appointmentSubHTML);
        $(".confirmation-bar").addClass("confirmation-bar-now");
        $("#gform_confirmation_message_19").addClass("confirmation-message-now");
        $(".form-page-step-confirmation").addClass("form-page-step-confirmation-now");
        $(".confirmation_button").addClass("confirmation_button_now");
        $(".confirmation-button-bar").addClass("confirmation-button-bar-now");
        const appointmentThanksHTML = '<p class="thank-you-now">Thank you for scheduling your consultation at ClearChoice.</p>';
        $(".confirmation-button-bar").append(appointmentThanksHTML);
        $(".remove").addClass("remove-now");
        $(".receive-bar").addClass("receive-bar-now");
        $(".atcb-link").addClass("atcb-link-now");
        $(".addtocalendar").addClass("addtocalendar-now");
        $(".date-time-label").addClass("date-time-label-now");
        $(".date-time-value").addClass("date-time-value-now");
        $(".date-time-bar").addClass("date-time-bar-now");
        $(".image-container").addClass("image-container-now");
        $(".gf_step_active").addClass("gf_step_active_now");
        $(".gf_step_pending").addClass("gf_step_pending_now");
        $(".accessibility_outline").addClass("accessibility_outline_now");
        $("#input_19_46").addClass("schedNowReskin");
      }

      //number of prompt to call messages a user clicks on before scheduling

      // Accordant Pixel: ClearChoice Paid Form Step 4 Confirmation Page (Thank You Confirmation page)
      var accordantLeadId = global.ccUserInfo.guid;

      var confirmation = $( '.gform_confirmation_message' );

      //google maps button
      var selectedCenter = global.ccUserInfo.availableCenters[ccUserInfo.centerID];
      var googleMapButton = '//maps.google.com/?q=' + selectedCenter.address + ' ' + selectedCenter.address2 + ' , ' + selectedCenter.city + ' , ' + selectedCenter.state + ' , ' + selectedCenter.zipcode;
      $( '.confirmation_button.directions' ).attr( 'href', googleMapButton );

      confirmation.find( '.confirmation-details .date' ).html( global.ccUserInfo.appointmentString );
      confirmation.find( '.confirmation-details .address' ).html( global.ccUserInfo.addressString );

      //ab test for sched confirm -----------start
      // var confirmTestCookie = Cookie.get('schedConfirmationTest');
      // if(confirmTestCookie){
        const appointmentDateAndTimeStrings = global.ccUserInfo.appointmentString.split('<br/>');
        const dateString = appointmentDateAndTimeStrings[0];
        const timeString = appointmentDateAndTimeStrings[1];

        $( '.confirmation-test .date' ).html( dateString );
        $( '.confirmation-test .time' ).html( timeString );

        var addressStringConfirm = `<span class="centerName">ClearChoice ${selectedCenter.name} Center</span>`;
        addressStringConfirm += `<p>${selectedCenter.address} `;
        addressStringConfirm += `${selectedCenter.address2}<br/>`;
        addressStringConfirm += `${selectedCenter.city}, ${selectedCenter.state} `;
        addressStringConfirm += `${selectedCenter.zipcode}</p>`;

        $( '.confirmation-test .address' ).html( addressStringConfirm );

        $("#section-to-print").hide();
        $(".top_confirmation_bar h1").hide();
        $(".top_confirmation_bar .confirmation_button").hide();
        $('.confirmation-test').show();

        helpers.scrollPageTo(".confirmation-test",80);
      // }
      //ab test for sched confirm -----------end

      //AddToCalendar
      $( '.atc_date_start' ).html( global.ccUserInfo.calendarTimeString );
      $( '.atc_date_end' ).html( global.ccUserInfo.calendarTimeEndString );
      $( '.atc_timezone' ).html( jstz.determine().name() );
      $( '.atc_location' ).html( selectedCenter.address + ' ' + selectedCenter.address2 + ', ' + selectedCenter.city + ', ' + selectedCenter.state + ' ' + selectedCenter.zipcode );

      // colocation changes to confimation page
      if(window.location.pathname.includes("colocation-schedule")){
         $('.confirmation-text')[0].innerHTML= "the patient appointment has been scheduled:";
         $('.bottom-confirmation').hide();
         $('.address').append('<br />');
      }
      //window.addtocalendar.load();

      //check for cookie for optimizely experiment
      //var testTextConfirmationPopUp = Cookie.get('testTextConfirmationPopUp');
      //var testTextConfirmationCheckBox = Cookie.get('testTextConfirmationCheckBox');
      //if(testTextConfirmationPopUp && !testTextConfirmationCheckBox){
        //show the text message consent popup
        //saveCampaign('Text_v2');
        //ccForms.showTextMessageTermsBox();
      //}

    } );




    /**
     * Run all the functions needed for the Confirmation page
     */

    let confirmationPage = function() {
      confirmationPageCheckboxClick();
      confirmationPageTextClick();
      //confirmationPageSave(); // removed per CC-227
      confirmationPageInit();

      // Change Call Center # to user's call center
      //$('.schedule-consultation-form .cancel-change-appt').html('To change or cancel your appointment, please call us at ' + callCenterNumber + ' at least 48 hours prior to your scheduled consultation time.');

    };

    // add the iSpot pixel for form visit event
    if($("#gform_19").length > 0){
      if( global.ccUserInfo.trans_id != undefined){
        channel = getCampaignSource();
      }
      if( global.ccUserInfo.guid != undefined){
        userId = global.ccUserInfo.guid;
      } else if( Cookie.get('GUID') != undefined ){
        userId = window.atob(Cookie.get('GUID'));
      }
      // append the visit for the pages with the main form
      addIspotPixel("form_visit",channel,userId);
      /**
        * * Xandr pixel
      */

    }
  },
};
